export default {
  methods: {
    // 科技系
    showScienceAlert() {
      this.dialog = this.$createDialog(
        {
          type: 'alert',
          confirmBtn: {
            text: '确定',
            active: true
          }
        },
        createElement => [
          createElement(
            'div',
            {
              class: {
                'title-ordinary': true
              },
              slot: 'title'
            },
            '科技体系'
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-category': true
                  }
                },
                'A类:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 联系过KP(有手机号码);'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 客户有真实需求;'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 有过实际拜访;'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 已到方案制作或报价环节;'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 近两月明确需要启动项目;'
                  )
                ]
              )
            ]
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-category': true
                  }
                },
                'B类:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 联系过KP(有手机号码);'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 客户有真实需求;'
                  ),
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    'ü 有过实际拜访;'
                  )
                ]
              )
            ]
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-category': true
                  }
                },
                'C类:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                'ü 联系过KP(有手机号码)'
              )
            ]
          )
        ]
      );
      this.dialog.show();
    },
    // 人才系
    showTalentsAlert() {
      this.dialog = this.$createDialog(
        {
          type: 'alert',
          confirmBtn: {
            text: '确定',
            active: true
          }
        },
        createElement => [
          createElement(
            'div',
            {
              class: {
                'title-ordinary': true
              },
              slot: 'title'
            },
            '人才体系'
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-categorys': true
                  }
                },
                'A类客户:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    '半年内预计合作100人以上的客户；'
                  )
                ]
              )
            ]
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-categorys': true
                  }
                },
                'B类客户:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    '半年内预计合作30(含)-100人之间的客户；'
                  )
                ]
              )
            ]
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-categorys': true
                  }
                },
                'C类客户:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    '半年内预计合作30人以下的客户；'
                  )
                ]
              )
            ]
          ),
          createElement(
            'div',
            {
              class: {
                'content-data': true
              },
              slot: 'content'
            }, [
              createElement(
                'div',
                {
                  class: {
                    'content-categorys': true
                  }
                },
                'D类客户:'
              ),
              createElement(
                'div',
                {
                  class: {
                    'content-explain': true
                  }
                },
                [
                  createElement(
                    'div',
                    {
                      class: {
                        'content-cont': true
                      }
                    },
                    '死亡商机；'
                  )
                ]
              )
            ]
          )
        ]
      );
      this.dialog.show();
    },
    // 产品说明
    showProductAlert() {
      this.dialog = this.$createDialog(
        {
          type: 'alert',
          confirmBtn: {
            text: '确定',
            active: true
          }
        },
        createElement => [
          createElement(
            'div',
            {
              class: {
                'title-ordinary': true
              },
              slot: 'title'
            },
            '产品说明'
          ),
          createElement(
            'div',
            {
              class: {
                'contents-product': true
              },
              slot: 'content'
            },
            [
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '金柚管家V1:派遣型管家'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '金柚管家V2:不含招聘全流程管家'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '金柚管家V3:含招聘全流程管家'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '金柚小灵V1:不含招聘小灵'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '金柚小灵V2:含招聘小灵'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '蓝精灵V1:不含招聘蓝精灵'
              ),
              createElement(
                'div',
                {
                  class: {
                    'contents-center': true
                  }
                },
                '蓝精灵V2:含招聘蓝精灵'
              )
            ]
          )
        ]
      );
      this.dialog.show();
    }
  }
};
