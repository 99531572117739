<template>
  <div class="form-custom section">
    <cube-form
      :model="model"
      class="form-wrapper"
      :options="formOptions"
      :submit-always-validate="true"
      @validate="validateHandler"
      @submit="submitHandler"
    >
      <div class="form-content">
        <cube-form-group class="form-group">
          <cube-form-item :field="fields[0]">
            <div
              class="field-wrapper"
              @click="toSearch('custCnCreditcode')"
            >
              <cube-input
                v-model="model.custCn"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i
                v-if="!custDisabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[1]">
            <div
              slot="label"
              class="cube-form-label"
            >
              <span>商机等级</span><i
                class="icon-joyo hint"
                @click="showAlert"
              >&#xe6be;</i>
            </div>
            <div class="field-wrapper">
              <cube-select
                v-model="model.oppoLevel"
                class="cube-select"
                :options="fields[1].props.options"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[2]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.busTypeIds"
                class="cube-select"
                :options="fields[2].props.options"
                placeholder="请选择"
                :disabled="fields[2].props.disabled"
                @change="changeCombo"
              />
              <i
                v-if="!fields[2].props.disabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[3]">
            <div
              slot="label"
              class="cube-form-label"
            >
              <span>产品类型</span><i
                v-if="system === 1"
                class="icon-joyo hint"
                @click="showProductAlert"
              >&#xe6be;</i>
            </div>
            <div class="field-wrapper">
              <cube-select
                v-model="model.comboId"
                class="cube-select"
                :options="comboIdOptions"
                placeholder="请选择"
                :disabled="fields[3].props.disabled"
              />
              <i
                v-if="!fields[3].props.disabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[4]">
            <div class="field-wrapper">
              <div
                class="cube-tagbox"
                @click="getPost"
              >
                <span v-if="model.industryName">{{ model.industryName }}</span>
                <div
                  v-else
                  class="color-ccc"
                >
                  {{ "请选择" }}
                </div>
              </div>
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[5]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.businessSource"
                class="cube-select"
                :options="fields[5].props.options"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[6]">
            <div
              class="field-wrapper"
              @click="toSearch('custContacts')"
            >
              <cube-input
                v-model="model.name"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <!-- 是否有供应商 -->
          <div
            v-if="system === 2"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[7]">
              <div class="field-wrapper">
                <cube-select
                  v-model="model.hasSupplier"
                  class="cube-select"
                  :options="fields[7].props.options"
                  placeholder="请选择"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <!-- 供应商名称 -->
          <div v-if="system === 2 && model.hasSupplier === 1">
            <cube-form-item :field="fields[8]" />
          </div>
          <!--          <cube-form-item :field="fields[9]">-->
          <!--            <cube-input-->
          <!--              v-model="model.expectedCooperation"-->
          <!--              type="text"-->
          <!--              placeholder="请输入"-->
          <!--              :clearable="clearable"-->
          <!--            />-->
          <!--          </cube-form-item>-->
          <cube-form-item :field="fields[9]" />
        </cube-form-group>
      </div>
      <div class="bottom-postion btn-return-other">
        <!-- <cube-button
          v-if="$route.query.from === 'qyWeChat'"
          type="submit"
          class="btn btns"
          @click="toContact"
        >
          新增联系人
        </cube-button> -->
        <cube-button
          v-if="$route.query.from === 'qyWeChat'"
          :light="true"
          class="btn-return"
          @click="$router.go(-1)"
        >
          返回
        </cube-button>
        <cube-button
          type="submit"
          class="btn-other"
          :disabled="saveDisabled"
        >
          保存
        </cube-button>
      </div>
    </cube-form>
    <search-view
      v-if="searchBool"
      :placeholder="placeholder"
      :search-type="searchType"
      :func="func"
      :default-func="defaultFunc"
      :default-request-data="params"
      :need-btn="$route.query.from === 'qyWeChat'"
      @conceal="conceal"
      @option="option"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { businessEditDetail, businessSave, businessUpdate, busTypeList, selectTitle } from '_api/bus';
import { getAllCustList, myCustWithBusOppoList } from '_api/global';
import { getCustContactsList } from '_api/sign';
import searchView from '_c/search';

import { findValueByKey } from '@/lib/until';
import dialog from '@/mixins/dialog';
import multiPicker from '@/mixins/multi-picker';
export default {
  name: 'AddeditBusiness',
  components: {
    searchView
  },
  mixins: [multiPicker, dialog],
  data() {
    return {
      custDisabled: false,
      searchBool: false,
      searchType: '',
      placeholder: '',
      func: function() {},
      defaultFunc: function() {},
      params: {},
      model: {
        clueId: 0,
        custCn: '',
        custId: '',
        oppoLevel: '',
        busTypeIds: '',
        comboId: '',
        industryId: '', // 行业类型
        industryName: '', // 行业类型展示
        businessSource: '', // 商机来源
        expectedCooperation: '', // 预计合作人数
        remark: '',
        custStatus: '',
        name: '',
        custContactsId: '',
        hasSupplier: '',
        supplierName: ''
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'custCn',
          label: '客户名称',
          props: {
            disabled: true
          },
          rules: {
            required: true
          }
        },
        // 1
        {
          type: 'select',
          modelKey: 'oppoLevel',
          label: '商机等级',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 2
        {
          type: 'select',
          modelKey: 'busTypeIds',
          label: '业务类型',
          props: {
            options: [],
            disabled: false
          },
          rules: {
            required: true
          }
        },
        // 3
        {
          type: 'select',
          modelKey: 'comboId',
          label: '产品名称',
          props: {
            options: [],
            disabled: false
          },
          rules: {
            required: true
          }
        },
        // 4
        {
          modelKey: 'industryName',
          label: '行业类型',
          rules: {
            required: true
          }
        },
        // 5
        {
          type: 'select',
          modelKey: 'businessSource',
          label: '商机来源',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 6
        {
          type: 'input',
          modelKey: 'name',
          label: '商机联系人',
          props: {
            placeholder: '请选择',
            disabled: true
          },
          rules: {
            required: true
          }
        },
        // 7
        {
          type: 'select',
          modelKey: 'hasSupplier',
          label: '是否有供应商',
          props: {
            options: [{ text: '是', value: 1 }, { text: '否', value: 0 }]
          },
          rules: {
            required: true
          }
        },
        // 8
        {
          type: 'textarea',
          modelKey: 'supplierName',
          label: '供应商名称',
          props: { placeholder: '请输入', maxlength: 50 },
          rules: {
            required: true
          }
        },
        // 9
        // {
        //   type: 'input',
        //   modelKey: 'expectedCooperation',
        //   label: '预计合作人数',
        //   props: { placeholder: '请输入', maxlength: 10 },
        //   rules: {
        //     type: 'text'
        //   }
        // },
        // 10
        {
          type: 'textarea',
          modelKey: 'remark',
          label: '备注',
          props: { placeholder: '请输入', maxlength: 50 }
        }
      ],
      formOptions: {
        scrollToInvalidField: true
      },
      clearable: {
        visible: true,
        blurHidden: true
      },
      saveDisabled: false
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'dictList', 'system']),
    comboIdOptions() {
      if (!this.$route.query.businessId) { // 新增
        return this.fields[3].props.options.filter(item => item.value !== '136')
      } else {
        if (this.model.comboId !== '136') { // 编辑
          return this.fields[3].props.options.filter(item => item.value !== '136')
        } else {
          return this.fields[3].props.options
        }
      }
    }
  },
  created() {
    // 获取字典
    this.getOptions();
    // 获取业务类型
    this.busTypeList();
  },
  mounted() {
    if (this.$route.query.businessId) {
      this.$route.meta.title = '编辑商机';
      this.toEdit(this.$route.query.businessId);
    }
    if (this.$route.query.from === 'qyWeChat') this.toQyWeChat();
  },
  methods: {
    ...mapMutations(['SET_KEEPALIVE']),
    // 判断是否商机状态为锁定
    isLock() {
      this.fields[2].props.disabled = true;
      this.fields[3].props.disabled = true;
    },
    getPost() {
      // this.SET_KEEPALIVE(['AddeditBusiness']); // 离开页面设置缓存
      this.showCitySegmentPicker();
    },
    toSearch(type) {
      if (type === 'custCnCreditcode' && this.custDisabled) {
        return;
      }
      switch (type) {
        case 'custCnCreditcode':
          this.placeholder = '请输入客户名称或统一信用代码';
          this.func = getAllCustList;
          this.defaultFunc = myCustWithBusOppoList;
          break;
        case 'custContacts':
          if (this.model.custCn === '') {
            return this.$showToast('请先选择客户名称');
          }
          this.SET_KEEPALIVE(['AddeditBusiness']); // 离开页面设置缓存
          this.placeholder = '请输入联系人姓名精确查询';
          this.searchName = this.model.name;
          this.defaultFunc = getCustContactsList;
          this.params = { custId: this.model.custId, pageNum: 1, pageSize: 100 };
          this.func = getCustContactsList;
          break;
      }
      this.searchType = type;
      this.searchBool = true;
    },
    option(data) {
      switch (this.searchType) {
        case 'custCnCreditcode':
          this.model.custId = data.id;
          this.model.custStatus = data.custStatus;
          this.model.custCn = data.title;
          break;
        case 'custContacts':
          this.model.name = data.name;
          this.model.custContactsId = data.id;
          this.model.custContacts = data.title;
          break;
      }
      // this.model[this.searchType] = data.title;
      this.searchBool = false;
    },
    conceal(bool) {
      this.searchBool = bool;
    },
    // 获取字典
    getOptions() {
      if (this.system === 1) {
        this.fields[1].props.options = this.dictList.oppoLevel.filter(item => item.text !== 'D');
      } else {
        this.fields[1].props.options = this.dictList.oppoLevel;
      }
      this.fields[5].props.options = this.dictList.clueSource;
    },
    // 商机等级判断
    judgeLevel(oppoLevel) {
      if (this.system !== 2) {
        this.$nextTick(() => {
          if (oppoLevel !== 1) {
            this.fields[1].props.options = this.fields[1].props.options.filter((item) => {
              return item.text !== 'A';
            });
            // 编辑为A且不等于人才系时，则下拉可选项展示：B、C;
          }
        });
      }
    },
    // 获取业务类型列表
    busTypeList() {
      busTypeList(1).then(res => {
        if (res.flag) {
          res.data.records.forEach(item => {
            this.fields[2].props.options.push(
              {
                text: item.bustypeTitle,
                value: item.id
              }
            );
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 获取产品
    changeCombo(val) {
      this.fields[3].props.options = [];
      selectTitle(val, 1).then(res => {
        if (res.flag) {
          res.data.records.forEach(item => {
            this.fields[3].props.options.push(
              {
                text: item.comboTitle,
                value: item.id
              }
            );
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 判断登录人体系
    showAlert() {
      if (this.system === 1) {
        this.showScienceAlert();
      } else if (this.system === 2) {
        this.showTalentsAlert();
      }
    },
    // 编辑回显
    toEdit(businessId) {
      this.custDisabled = true;
      businessEditDetail(businessId).then(res => {
        if (res.flag) {
          this.model = Object.assign(this.model, res.data);
          this.model.supplierName = res.data.supplierName || '';
          this.model.remark = res.data.remark || '';
          this.model.name = this.model.custContactsName;
          this.model.supplierName = res.data.supplierName || '';
          this.model.remark = res.data.remark || '';
          let str = '';
          res.data.fatherIndustryList && res.data.fatherIndustryList.forEach(item => {
            str += item.moduleName + '/';
          });
          this.model.industryName = str.slice(0, str.length - 1);
          this.judgeLevel(res.data.oppoLevel);
          this.changeCombo(res.data.busTypeIds);
          if (res.data.oppoStatus === 8) this.isLock();
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 从企微进来
    toQyWeChat() {
      const { custId, custCn, custStatus } = this.$route.query;
      this.model.custId = custId;
      this.model.custStatus = custStatus;
      this.model.custCn = custCn;
      this.custDisabled = true;
    },
    // 跳转新增联系人
    // toContact() {
    //   let {custId, custCn} = this.$route.query;
    //   this.$router.push(
    //     {
    //       name: 'addedit-contact',
    //       query: {
    //         custCn: custCn,
    //         custId: custId,
    //         from: 'qyWeChat'
    //       }
    //     }
    //   );
    // },
    // 效验报错提示
    validateHandler(result) {
      // if (!result.valid) {
      //   for (let i in result.validity) {
      //     if (result.validity[i].valid === false) {
      //       for (let j = 0 ; j < this.fields.length; j++) {
      //         let item = this.fields[j];
      //         if (item.modelKey === i) {
      //           this.$showToast(item.label + '为空，不能保存');
      //           return;
      //         }
      //       }
      //     }
      //   }
      // }
    },
    submitHandler(e) {
      e.returnValue = false;
      this.saveDisabled = true;
      const params = JSON.parse(JSON.stringify(this.model));
      params.id = this.$route.query.businessId || 0;
      params.orgId = this.userInfo.postRoleResList[0].orgId;
      params.sellerOrgId = this.userInfo.postRoleResList[0].orgId;
      params.comboName = findValueByKey(this.fields[3].props.options, params.comboId);
      params.hasSupplier = this.system === 2 ? this.model.hasSupplier : null;
      params.supplierName = params.hasSupplier === 1 ? this.model.supplierName : '';
      const fun = this.$route.query.businessId ? businessUpdate : businessSave;
      fun(params).then(res => {
        if (res.flag) {
          this.saveDisabled = false;
          this.$router.go(-1);
          this.$showToast('保存成功');
          this.SET_KEEPALIVE([]);
        } else {
          this.$showToast(res.errorMsg);
          this.saveDisabled = false;
          this.SET_KEEPALIVE([]);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
