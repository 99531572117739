import { findList } from '_api/bus';

export default {
  data() {
    return {
      overallData: [],
      firstOrder: [],
      secondOrder: [],
      thirdOrder: [],
      fourOrder: []
    };
  },
  created() {
    this.firstStage();
  },
  mounted() {
    this.reconstruction();
  },
  methods: {
    showCitySegmentPicker() {
      this.citySegmentPicker.show();
    },
    reconstruction() {
      this.citySegmentPicker = this.$createSegmentPicker({
        data: [{
          title: '第一层级',
          data: [this.firstOrder],
          selectedIndex: [0]
        }, {
          // is: 'cube-cascade-picker',
          title: '第二层级',
          data: [this.secondOrder],
          selectedIndex: [0],
          cancelTxt: '返回'
        }, {
          // is: 'cube-cascade-picker',
          title: '第三层级',
          data: [this.thirdOrder],
          selectedIndex: [0],
          cancelTxt: '返回'
        }
        // ,
        // {
        //   // is: 'cube-cascade-picker',
        //   title: '第四层级',
        //   data: [this.fourOrder],
        //   selectedIndex: [0]
        // }
        ],
        cancelTxt: '取消',
        confirmTxt: '确定',
        nextTxt: '下一步',
        prevTxt: '上一步',
        onNext: (selectedVals, selectedIndexs) => {
          console.log(selectedVals);
          switch (selectedVals) {
          case 0:
            this.secondStage(selectedIndexs[0]);
            break;
          case 1:
            this.thirdStage(selectedIndexs[0]);
            break;
          // case 2:
          //   this.fourStage(selectedIndexs[0]);
          //   break;
          }
        },
        onSelect: (selectedVals, selectedIndexs, selectedTexts) => {
          console.log(selectedVals, selectedIndexs, selectedTexts);
          this.model.industryId = '';
          this.model.industryName = '';
          let str = '';
          selectedTexts.forEach(item => {
            str += item.join('') + '/';
          });
          this.model.industryId = selectedVals[selectedVals.length - 1].join(',');
          this.model.industryName = str.slice(0, str.length - 1);
          this.secondOrder = [];
          this.thirdOrder = [];
          this.fourOrder = [];
          this.reconstruction();
        }
      });
    },
    firstStage() {
      findList().then(res => {
        this.overallData = res.data;
        this.overallData.forEach(item => {
          this.firstOrder.push({text: item.moduleName, value: item.id});
        });
      });
    },
    secondStage(fatherId) {
      this.overallData.forEach(item => {
        if (item.sonIndustryList[0].fatherId === fatherId) {
          item.sonIndustryList.forEach(items => {
            this.secondOrder.push({text: items.moduleName, value: items.id, sonIndustryList: items.sonIndustryList});
          });
        }
      });
    },
    thirdStage(fatherId) {
      this.secondOrder.forEach(item => {
        if (item.sonIndustryList[0].fatherId === fatherId) {
          item.sonIndustryList.forEach(items => {
            this.thirdOrder.push({text: items.moduleName, value: items.id, sonIndustryList: items.sonIndustryList});
          });
        }
      });
      this.thirdOrder.unshift({text: '未知', value: fatherId});
      console.log(this.thirdOrder);
    }
    // ,
    // fourStage(fatherId) {
    //   // 判断是否有第四层
    //   if (this.thirdOrder[0].sonIndustryList) {
    //     this.thirdOrder.forEach(item => {
    //       if (item.sonIndustryList[0].fatherId === fatherId) {
    //         item.sonIndustryList.forEach(items => {
    //           this.fourOrder.push({text: items.moduleName, value: items.id, sonIndustryList: items.sonIndustryList});
    //         });
    //       }
    //     });
    //   } else {
    //     this.fourOrder.push(...this.thirdOrder);
    //   }
    //   this.fourOrder.unshift({text: '未知', value: fatherId});
    // }
  }
};
